@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.CopyTextInput {
  position: relative;
}

.Input {
  background-color: ui.$main-white-full;
  input {
    color: black;
  }
}

.Tooltip {
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  padding: 0.5rem 2rem;
  color: white;
  width: 20em;
  background-color: ui.$main-blue-full;
  border-radius: 0.25rem;
  display: none;
  margin-top: 0.75rem;
  z-index: 1;
  &.Enabled {
    display: block;
  }
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    display: block;
    width: 0px;
    bottom: 100%;
    border: 0.5rem solid transparent;
    border-top: 0;
    border-bottom: 0.5rem solid ui.$main-blue-full;
    transform: translate(-50%);
  }
}
