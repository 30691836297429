@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.ListCustomLinksScreen {
  background-color: ui.$main-beige-full;
  display: flex;
  flex: 1 1;
  padding: 1.5em 1.5em;
  gap: 1em;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
}

.TitleBar {
  display: flex;
  align-items: center;
  h2 {
    @include ui.Lleft;
    flex: 1 1;
  }
}

.Filters {
  display: flex;
  gap: 1rem;
  > :first-child {
    margin-top: 1rem;
    align-self: center;
  }
  & > * + * {
    flex: 1;
  }
}

.Cross path {
  fill: ui.$main-blue-full;
}

.AgencyLink {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
}

.Card {
  height: 4.5rem;
  border-color: transparent;
}

.Status {
  font-size: 0.75rem;
  color: ui.$system-danger;
  align-self: stretch;
  display: flex;
  align-items: center;
  position: relative;
  &.Active {
    color: ui.$system-sucess;
  }
  .Tooltip {
    cursor: default;
    text-align: center;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    right: 100%;
    padding: 0.5rem 2rem;
    color: white;
    width: 22em;
    background-color: ui.$main-blue-full;
    border-radius: 0.25rem;
    display: none;
    margin-right: 1rem;
    z-index: 1;
    transform: translateY(-50%);
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      display: block;
      width: 0px;
      border: 0.5rem solid transparent;
      border-left: 0.5rem solid ui.$main-blue-full;
      transform: translateY(-50%);
    }
  }
  &:hover .Tooltip {
    display: block;
  }
}
