@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.MenuSectionTitle {
  @include ui.XSwhitefullleft;
  margin-top: 0.5rem;
  margin-left: 0.75rem;
  margin-bottom: 1rem;
  position: relative;
  height: 1em;
}

.WhiteDash {
  top: 0;
  position: absolute;
  margin-left: 0.25rem;
}

.Title {
  position: absolute;
  top: 0.25rem;
  text-wrap: nowrap;
  user-select: none;
}
