@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  max-height: 100%;
  width: 100%;
  overflow: hidden;
}

.partTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
}

.partTitleText {
  @include Lnavyleft;
  // Ellipsis on first line
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.unitsWithoutCGCListContainer {
  max-height: calc(100% - 3.5rem);
  width: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

.unit {
  width: 100%;
  cursor: pointer;
  label {
    cursor: pointer;

    div {
      width: 100%;
    }
  }
  &:hover {
    background-color: $contrast-grey-light;
  }
}

.unitCheckbox {
  width: 100%;
}

.unitCard {
  width: 100%;
  margin: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.loadingText {
  @include Snavyleft;
  margin-bottom: 0.5rem;
}
