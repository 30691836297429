@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.AgencyEditCalendarScreen {
  background-color: ui.$main-beige-full;
  display: flex;
  gap: 1rem;
  flex: 1;
  padding: 1.5rem 1.5rem;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
}

.TitleContainer {
  display: flex;
  > :first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.Title {
  @include ui.Lleft;
}

.Paragraph {
  color: ui.$contrast-grey-strong;
}

.Link {
  align-self: flex-start;
  margin-top: 0.5rem;
  color: ui.$main-blue-full;
  &:hover {
    color: ui.$main-navy-full;
  }
}
