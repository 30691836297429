@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-bottom: 2rem;
}

.appNewsCardTitle {
  @include XSgrey_mediumleft;
}

.appNewsCardSubtitle {
  @include Mnavyleft;
  // Ellipsis on first line
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
