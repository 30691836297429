@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.CGCListContainer {
  max-height: calc(100% - 10rem);
  width: 100%;
  overflow-y: auto;
  margin-top: 2rem;
  padding-right: 1rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.CGCCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.CGCName {
  @include Snavyleft;
  margin-bottom: 0.25rem;
}

.CGCUnits {
  @include XSgrey_mediumleft;
}

.CGCEditButton {
  height: fit-content;
  min-width: fit-content;
  padding: 0.25rem;
}
