@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.content {
  background-color: $main-beige-full;
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0px;
  border-radius: 0px 0px 0px 8px;
}
