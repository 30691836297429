@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin: 2rem;
  position: relative;
  svg {
    width: 200%;
    height: 200%;
  }
}

.image {
  position: absolute;
  top: 4.5rem;
}

.title {
  @include Lnavyleft;
}
