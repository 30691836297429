@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.InspectCustomLinkScreen {
  background-color: ui.$main-beige-full;
  display: flex;
  gap: 1rem;
  flex: 1 1;
  padding: 1.5em 1.5rem;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
}

.TitleContainer {
  display: flex;
  justify-content: space-between;
}

.TextContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.Title {
  @include ui.Lleft;

  span {
    @include ui.Mleft;
  }
}

.Subtitle {
  @include ui.Mgrey_mediumleft;
}

.OptionalSteps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;

  .OptionalStep {
    align-self: flex-end;
    display: flex;
    gap: 0.25rem;

    .Danger path {
      fill: ui.$system-danger;
    }
    .Success path {
      fill: ui.$system-sucess;
    }
  }
}

.Paragraph {
  color: ui.$contrast-grey-strong;
}

.WarnColor {
  color: ui.$system-danger;
}

.SuccessColor {
  color: ui.$system-sucess;
}

.DropDown {
  background-color: ui.$main-white-full;
}

.QRCodeContainer {
  position: relative;
  width: 30em;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  background-color: ui.$main-white-full;
  margin-inline: auto;
  gap: 2rem;

  img {
    width: 100%;
    height: auto;
  }
}
