@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  width: 100%;
  background-color: #f7f5f0;

  cursor: pointer;
  &:not(.disabled) {
    &:hover {
      box-shadow: 0 0 0 2px $main-blue-full;
    }
  }
}

.highlightedAppBackgroundContainer {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  justify-content: flex-end;
  width: 100%;
}

.highlightedAppBackground {
  overflow: visible;
}

.textContainer {
  position: absolute;
  z-index: 2;
  width: 16rem;
  height: 12rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 0.5rem 0 0 0.5rem;
  padding: 1.5rem;
  opacity: 0.9;
}

.category {
  @include Sblueleft;
  margin-top: 0.25rem;
}

.name {
  @include Lwhiteleft;
}

.description {
  @include Swhitefullleft;
  margin-top: 0.75rem;
  // Ellipsis on third line
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
