@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-bottom: 0.5rem;
}

.subtitle {
  @include Snavyleft;
}
