@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-bottom: 1rem;
}

.formatSelectionContainer {
  margin-bottom: 1.5rem;
}

.buttonsContainer {
  height: 11rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.button {
  height: 100%;
}
