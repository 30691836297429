@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: $main-blue-full;
}

.text {
  @include Mcenter;
  padding-bottom: 2rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 1;
}
