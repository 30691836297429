@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-beige-full;

  padding: 0rem 2rem;
  border-radius: 8px 0px 0px 0px;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
}

.separator {
  @extend %separator;
}
