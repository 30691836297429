.CustomRandy {
  width: 100%;
  height: 100%;
  display: flex;
}

.ContentContainer {
  flex: 1 1;
  display: flex;
  z-index: 1;
  border-radius: 0px 0px 0px 8px;
  overflow: hidden;
}
