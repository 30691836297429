@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.content {
  background-color: $main-beige-full;
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0px;
  border-radius: 0px 0px 0px 8px;
  padding: 2rem;

  /* Scrollbar style */
  ::-webkit-scrollbar {
    width: 0.375rem;
  } /* width */
  ::-webkit-scrollbar-track {
    border-radius: 38px;
    background: $main-blue-light;
  } /* Track */
  ::-webkit-scrollbar-thumb {
    background: $main-blue-full;
    border-radius: 38px;
  } /* Handle */
}

.partTitle {
  @include Lnavyleft;
  margin-bottom: 1rem;
  // Ellipsis on first line
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.unitsPart {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.unitsWithoutCGCPart {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
  margin-bottom: 1rem;
}

.unitsWithCGCPart {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
}

.CGCPart {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  margin-left: 2rem;
}
