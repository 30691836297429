@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.profileContainer {
  margin-top: 1rem;
}

.profileDropdownChildren {
  max-height: 12rem;
  overflow: auto;
}

.seeDetails {
  @include XSblueleft;
  cursor: pointer;
}
