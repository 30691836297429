@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.CustomDropdownOption {
  color: ui.$main-blue-full;
  line-height: 1.25;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;

    path {
      fill: currentColor;
    }
  }
}

.Separator {
  min-width: 0.5rem;
}

.CustomDropdownOption:hover {
  background-color: ui.$contrast-grey-lighter;
}

.Selected {
  color: ui.$main-navy-full;
  background-color: ui.$contrast-grey-light;
}

.Label {
  overflow: hidden;
  flex: 1 1;
}

.RightIcon {
  margin-left: 1rem;
  justify-self: flex-end;
  max-height: 1rem;
}

.LeftIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
