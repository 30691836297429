@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  width: 100%;
  height: 100%;
  background-color: $main-beige-full;
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.mainTitle {
  @include XXLnavyleft;
  margin-bottom: 1rem;
}

.appContainer {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: normal;
}
.newsContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.dashContainer {
  margin: 0.75rem 1.875rem 0.75rem 1.5rem;
}
.dash {
  width: 72px;
  height: 24px;
  background-color: $main-yellow-full;
  border-radius: 20px;
}
.iconContainer {
  margin-right: 64px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  svg {
    transform: scale(1.5) translate(0.75rem, 0.75rem);
  }
}
.newsFirstLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.appName {
  @include XLnavyleft;
}

.version {
  @include Sgrey_mediumleft;
}

.newsTitle {
  @include Snavyleft;
  margin-bottom: 0.25rem;
}

.newsDescription {
  @include Sgrey_mediumleft;
}
