@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.unitsContainer {
  margin-top: 1.5rem;
}

.unitsCount {
  @include Snavyright;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.UnitCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.UnitId {
  @include Snavyleft;
  margin-bottom: 0.25rem;
}

.UnitLabel {
  @include XSgrey_mediumleft;
}

.UnitDeleteButton {
  height: fit-content;
  min-width: fit-content;
  padding: 0.25rem;
}
