@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 8px;
  background-color: $main-beige-full;

  &:not(.disabled) {
    &:hover {
      box-shadow: 0 0 0 2px $main-blue-full;
    }
  }
}

.text {
  margin: 1rem;
}

.iconContainer {
  width: 100%;
  height: 8rem;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: center;
  svg {
    transform: scale(2) translate(0, 1rem);
  }
}

.category {
  @include XSblueleft;
}

.name {
  @include Mnavyleft;
}

.description {
  @include XSgrey_mediumleft;
}
