@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  min-width: 30rem;
}

.appsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  column-gap: 2rem;
  row-gap: 1.5rem;
}
