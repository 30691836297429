@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding-bottom: 2rem;
}

.sectionSeparator {
  min-height: 0.063rem;
  background-color: $contrast-grey-medium;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
