@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding-bottom: 2rem;
}

.sectionSeparator {
  min-height: 0.063rem;
  background-color: $contrast-grey-medium;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.title {
  @include Lnavyleft;
  margin-bottom: 1rem;
}

.appDetailsCardSubtitle {
  @include XSgrey_mediumleft;
}

.appDetailsCardTitle {
  @include Mnavyleft;
  // Ellipsis on first line
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.badge {
  background-color: $system-info;
}
