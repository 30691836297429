@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.profilesTable {
  width: 100%;
  thead {
    th {
      div {
        @include XSgreenishleft;
        padding-right: 1rem;
      }
    }
  }
  tbody {
    :hover {
      outline: none;
      outline-offset: 0px;
    }
  }
}

.unitaryRight {
  @include XSnavyleft;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
}

.dotContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.dot {
  display: inline-block;
  margin-left: 1rem;
  height: 0.75rem;
  min-width: 0.75rem;
  border-radius: 50%;
  background-color: $main-blue-full;
}
