@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1rem;
  justify-content: space-between;
  column-gap: 2rem;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.leftSideLoadingContainer {
  width: 66%;
}

.leftSideBigImageContainer {
  width: 66%;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
}

.leftSideBigImageImage {
  height: 100%;
  width: fit-content;
}

.leftSideBigImageText {
  @include XXXXLnavyright;
  position: absolute;
  bottom: 5%;
  right: 52%;
  width: 40%;
  padding: 2rem;
}

.leftSideSmallImageContainer {
  width: 66%;
}

.leftSideSmallImageImageContainer {
  height: 66%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.leftSideSmallImageImage {
  width: 100%;
  height: fit-content;
}

.leftSideSmallImageText {
  @include XXXXLnavyright;
  position: absolute;
  bottom: 47%;
  right: 52%;
  width: 40%;
  padding: 2rem;
}

.leftSideSmallImagePatchnoteContainer {
  height: fit-content;
}

.leftSideNoImageContainer {
  width: 66%;
  min-height: 100%;
  height: fit-content;
}

.randstadAppsDetails {
  width: 34%;
  height: fit-content;
  margin-bottom: 2rem;
}
