@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.AgencyListScreen {
  background-color: ui.$main-beige-full;
  display: flex;
  flex: 1;
  gap: 1rem;
  padding: 1.5rem 1.5rem;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
}

.Title {
  @include ui.Lleft;
}

.Filters {
  display: flex;
  gap: 1rem;
  & > * {
    flex: 1;
  }
}

.Icon path {
  fill: ui.$main-blue-full;
  transform-origin: center;
  transform: scale(1.4);
}

.AgencyCard {
  height: 2.5rem;
  padding: 0;
  border: none;
}
