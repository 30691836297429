@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.SideBar {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  background-color: ui.$main-navy-full;
}

.Logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.LogoImage {
  min-width: 2rem;
  height: 2rem;
}

.BottomButtons {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.AppTitle {
  @include ui.Swhitefullleft;
}

.Item {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  opacity: 0.6;
  overflow: hidden;
}

.Disconnect {
  @extend .Item;
  margin-left: 0.5rem;
  width: 3rem;
  height: 3rem;
}

.MenuItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.VerticalSeparator {
  background-color: ui.$contrast-grey-medium;
  width: 1px;
  height: 1.688rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.RetractButton {
  display: flex;
  flex-direction: row;
  height: 3rem;
  width: 110%;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  background-color: ui.$main-yellow-full;
  color: ui.$main-white-medium;
  cursor: pointer;
}

.RetractIcon {
  height: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
