@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.navyColor {
  background-color: $main-navy-full;
}

.warmredColor {
  background-color: $main-warmred-full;
}

.blueColor {
  background-color: $main-blue-full;
}

.yellowColor {
  background-color: $main-yellow-full;
}

.greenishColor {
  background-color: $main-greenish-full;
}
