@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-beige-full;
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0px;
  border-radius: 0px 0px 0px 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-left: 2rem;
  margin-right: 10rem;
  width: 100%;
}

.switchButton {
  height: 2.25rem;
  width: 27rem;
  margin-bottom: 1.5rem;
}

.switchButtonText {
  text-align: left;
  font-size: 16px;
  line-height: 1.25;
}

.selectedSwitchButtonBackground {
  height: 2.25rem;
}

.sectionSeparator {
  min-height: 0.063rem;
  background-color: $contrast-grey-medium;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
