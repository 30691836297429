@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.CustomDropdownComponent {
  .Select {
    height: 3.5em;
    background-color: ui.$main-white-full;
    border: 1px solid ui.$contrast-grey-medium;
    padding: 1px 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover,
    &.Focused {
      padding: 0 calc(1rem - 1px);
      border: 2px solid ui.$main-blue-full;
      outline: none;
      box-shadow: none;
    }
    &.Error {
      border: solid 2px ui.$system-danger;
      padding: 0 calc(1rem - 1px);
    }

    .ValueContainer {
      display: flex;
      gap: 0.5em;
    }

    .ValueContainerChildren {
      align-items: center;
      display: grid;
      flex: 1;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
    }

    .Placeholder {
      color: ui.$main-blue-full;
    }
    &.Error .Placeholder {
      color: ui.$system-danger;
    }
    &.Disabled .Placeholder {
      color: ui.$contrast-grey-strong;
    }

    .IndicatorSeparator {
      display: none;
    }

    .IndicatorContainer {
      margin: 0;
    }

    .Arrow {
      transition: transform 400ms ease-out;
    }
    &.Disabled {
      svg path {
        fill: ui.$contrast-grey-medium;
      }
    }
    &.Open .Arrow {
      transform: rotate(180deg);
    }

    .CrossIcon {
      margin-right: 0.5rem;
      path {
        fill: ui.$main-blue-full;
      }
    }
  }

  .Menu {
    margin-top: 0;
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0 1px 5px 0 ui.$contrast-grey-medium;
  }

  .MenuList {
    padding: 0.25rem;
  }

  .NoOptionsMessage {
    line-height: 1.25;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    color: ui.$contrast-grey-strong;
  }
}
