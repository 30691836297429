.BackButton {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.Button {
  height: 100%;
  width: 100%;
  padding: 0;
  color: white;
  &:hover {
    background-color: transparent !important;
    svg circle {
      opacity: 0.8;
    }
  }
  svg {
    height: 100%;
    width: 100%;
  }
}
