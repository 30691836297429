@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.CreateCustomLinkScreen {
  background-color: ui.$main-beige-full;
  display: flex;
  flex: 1;
  gap: 1rem;
  padding: 1.5rem 1.5rem;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
}

.Title {
  @include ui.Lleft;
}

.Paragraph {
  color: ui.$contrast-grey-strong;
}

.Card {
  height: 4.5rem;
  border-color: transparent;
}

.RadioGroup {
  display: flex;
  gap: 32px;
}
