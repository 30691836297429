@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.AgencyConfigScreen {
  background-color: ui.$main-beige-full;
  display: flex;
  gap: 1em;
  flex: 1;
  padding: 1.5rem 1.5rem;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
}

.Title {
  @include ui.Lleft;
}

.ContactForm {
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: stretch;
}

.ToggleContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  & > :first-child {
    display: flex;
  }
}

.Paragraph {
  color: ui.$contrast-grey-strong;
}

.Link {
  display: inline-block;
  margin-top: 0.5rem;
  color: ui.$main-blue-full;
  &:hover {
    color: ui.$main-navy-full;
  }
}

.CalendarList {
  display: flex;
  flex-direction: column;
}
