@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.InputLabel {
  display: flex;
  justify-content: space-between;
}

.LabelText {
  @include ui.XSgrey_mediumleft;
  margin-bottom: 0.25rem;
}

.ExplainButton {
  font-size: 0.75rem;
  position: relative;
  color: ui.$main-blue-full;
  cursor: pointer;
}

.Tooltip {
  cursor: default;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0.5rem 2rem;
  color: white;
  width: 20em;
  background-color: ui.$main-blue-full;
  border-radius: 0.25rem;
  display: none;
  margin-top: 0.75rem;
  z-index: 1;
  &.Enabled {
    display: block;
  }
  &::before {
    content: '';
    position: absolute;
    right: 1rem;
    display: block;
    width: 0px;
    bottom: 100%;
    border: 0.5rem solid transparent;
    border-top: 0;
    border-bottom: 0.5rem solid ui.$main-blue-full;
    transform: translate(-50%);
  }
}
